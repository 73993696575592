import { cn } from '#app/utils/misc'

interface BlackBackgroundColorForImagesProps {
	className?: string
}

const BlackBackgroundColorForImages = ({
	className,
}: BlackBackgroundColorForImagesProps) => {
	return (
		<div
			className={cn('absolute inset-0 bg-black opacity-20', className)}
		></div>
	)
}

export default BlackBackgroundColorForImages
